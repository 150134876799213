.card-info {
  margin: auto;
  width: 30vw;
  .row {
    .category {
      margin-bottom: 0;
    }
  }
  .card-cover {
    height: 130px;
    background-position: center center;
    background-size: cover;
    border-radius: $border-radius-extreme $border-radius-extreme 0 0;
  }
  .card-avatar {
    max-width: 120px;
    max-height: 120px;
    margin: -60px auto 0;
    border-radius: 50%;
    overflow: hidden;

    &.border-white {
      border: 4px solid $white-color;
    }
    &.border-gray {
      border: 4px solid $card-muted-color;
    }
  }

  .card-image + .category {
    margin-top: 20px;
  }
}
