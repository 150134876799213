.user-info {
  width: 60%;
  .user-info-main {
    // flex: 100;
  }
  .user-info-sub {
    font-size: 12px;
    // float: right;
  }
}
