.modalLogo {
  position: absolute;
  top: -60px;
  left: 35%;
  width: 100px;
}

.font-small {
  font-size: $font-size-small;
}
