.generated-form-main-container {
  margin: $margin-small-vertical $padding-small-horizontal;
  .unscrollable {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    .form-header-unscrollable {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      font-size: 80%;
      padding: 0.5rem;
    }
  }

  .form-header {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    font-size: 80%;
    padding: 0.5rem;

    .checkbox-label {
      font-size: 75%;
      // inline-size: 150px;
      // overflow-wrap: break-word !important;
    }
  }

  .del {
    background-color: green !important;
    ::before {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
    }
  }
  .form-fields-container {
    padding: 0.5rem;

    .box-error-sm {
      list-style-type: none;
      border: $border-thin solid $light-red;
      border-radius: 0.25rem;
      background: $light-red;
      padding: $padding-small-vertical $padding-small-horizontal;
      box-shadow: $dropdown-shadow;
      font-size: $font-size-small;
      margin-bottom: 0;
    }
  }

  .centered-items {
    text-align: center !important;
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    align-items: center;
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .generated-form-checkbox {
    position: relative !important;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    // vertical-align: top;
  }
  .scrollable {
    margin-top: 7px;
    border: 1px solid black;
    overflow-y: scroll;
    max-height: 600px;
    width: 100%;

    .generated-form-container {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .centered-items {
    &.col .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto > * {
      padding-left: $padding-xs-vertical !important;
      padding-right: $padding-xs-vertical !important;
    }
  }
}
