.flex-center {
  display: flex;
  justify-content: center;
  margin-top: 20vh;
}

.login-card {
  width: 18rem;
  min-height: 30vh;
  box-shadow: $box-shadow-raised-sm;
  
  .login-img {
    position: absolute;
    border-radius: 50%;
    top: -60px;
    left: 35%;
    width: 100px;
    box-shadow: $logo-shadow;
  }
}

