.box-info {
  position: relative;
  top: -2.5em;
  border: $border-thin solid $light-gray;
  border-radius: 0.25rem;
  background: $white-bg;
  padding: $padding-simple-vertical $padding-simple-horizontal;
  box-shadow: $dropdown-shadow;
}

.box-error {
  position: relative;
  top: 0.5rem;
  margin-bottom: 0;
  border: $border-thin solid $light-red;
  border-radius: 0.25rem;
  background: $light-red;
  padding: $padding-simple-vertical $padding-medium-horizontal;
  box-shadow: $dropdown-shadow;
  font-size: 0.8rem;
}