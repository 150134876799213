.head-container {
  padding: $padding-base-vertical $padding-base-horizontal;
  margin: $margin-large-vertical $margin-large-horizontal;
  @media (max-width: 576px) {
    margin: $margin-small-vertical $margin-small-horizontal;
  }
}

.main-container {
  padding: $padding-base-vertical $padding-base-horizontal;
  margin: $margin-large-vertical $margin-large-horizontal;
  .list-item {
    display: flex;
    justify-content: space-around;
    .participation-item {
      flex: 4;
      margin: auto;
    }
    .swd-name {
      flex: 3;
      margin: auto;
    }
    .swd-id {
      flex: 2;
    }
    .year-item {
      flex: 1;
      text-align: center;
      margin: auto;
    }
    .datatype-item {
      flex: 1;
      text-align: center;
      margin: auto;
    }
    .verification-item {
      flex: 1;
      text-align: center;
      margin: auto;
    }
  }

  // @media (max-width: 576px) {
  //   margin: $margin-small-vertical $margin-small-horizontal;
  // }
  @media (max-width: 768px) {
    margin: $margin-small-vertical $margin-small-horizontal;
  }
  @media (max-width: 960px) {
    margin: $margin-medium-vertical $margin-medium-horizontal;
  }
  // @media (min-width: 1200px) {
  //   // width: 30vw;
  //   //   }
  //   // @media (min-width: 1400px) {
  // }
}
.head-xl-container {
  padding: $padding-base-vertical $padding-base-horizontal;
  margin: $margin-small-vertical $margin-xs-horizontal $margin-large-vertical $margin-large-vertical;
}
.main-xl-container {
  padding: $padding-base-vertical $padding-base-horizontal;
  margin: $margin-large-vertical $margin-xs-horizontal;
}
.container-items-centered {
  display: flex;
  justify-content: space-around;
}



.font-small {
  font-size: $font-size-h6;
}
.box-shadow {
  box-shadow: $box-shadow-raised-xs;
}

.swd-info {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}
