.fixedPlugin {
  position: fixed;
  top: 2 * $padding-base-vertical;
  left: 0;
  padding: $padding-small-vertical $padding-small-horizontal;
  background: $black-lighten-bg;
  color: $white-color;
  z-index: 1031;
  border-radius: 0 $border-radius-large $border-radius-large 0;
}

.faCog {
  color: $white-color;
  padding: $padding-small-vertical $padding-small-horizontal;
}

.dropdownMenu {
  position: fixed;
  left: $none;
  padding: $padding-medium-vertical $padding-small-horizontal;
  width: 16.666667%;
  height: 48vh;
  border-radius: $border-radius-icon-sm;
  box-shadow: $dropdown-shadow;
  background: $white-bg;

  //   @media (min-width: 576px) {
  //     width: 80vw;
  //   }
  //   @media (min-width: 768px) {
  //     width: 60vw;
  //   }
  // @media (min-width: 960px) {
  //     width: 30vw;
  //   }
  @media (min-width: 1200px) {
    // width: 30vw;
    //   }
    // @media (min-width: 1400px) {
    width: 10vw;
  }
}

.switchUserMenu {
  position: fixed;
  top: 63vh;
  left: $none;
  // margin-top: 3vh;
  padding: $padding-medium-vertical $padding-small-horizontal;
  width: 16.666667%;
  min-height: 30vh;
  border-radius: $border-radius-icon-sm;
  box-shadow: $dropdown-shadow;
  background: $white-bg;

  //   @media (min-width: 576px) {
  //     width: 80vw;
  //   }
  //   @media (min-width: 768px) {
  //     width: 60vw;
  //   }
  // @media (min-width: 960px) {
  //     width: 30vw;
  //   }
  @media (min-width: 1200px) {
    // width: 30vw;
    //   }
    // @media (min-width: 1400px) {
    width: 10vw;
  }
}